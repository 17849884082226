<template>
  <div class="relative z-10 text-center h-full step1 mx-6">
    <div class="self-end">
      <h5 class="text-pink-cs text-base">نوع ملک</h5>
      <span class="text-white mt-8 mb-16 text-xs">نوع ملک خودتو مشخص کن</span>
    </div>
    <div class="radio step-3-radio">
      <div class="radio__group" v-for="(item ,i) in houseTypes" :key="i">
        <input type="radio" class="radio__button" v-model="selected" name="typeAds" :value="item">
        <label  class="radio__title">{{item}}</label>
      </div>
    </div>
    <div class="w-full self-center">
      <a class="btn-custom" @click="next">
        بررسی و ادامه
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
<svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "../../plugins/axios";

export default {
  name: "EditStep3",
  data(){
    return{
      houseTypes : null,
      selected : null
    }
  },
  methods :{
    next(){
      if (this.selected === null || this.checked === ''){
        this.$toast.error('حتما یک مورد را باید انتخاب کنید')
      }else {
        this.$store.commit('editEstate/SET_HOUSE_TYPES',this.selected)
        this.$router.push({name : 'EditEstateStep4'})

      }
    },

  },
  computed : {
    ...mapState({
      houseType : state => state.addEstate.house_type
    })
  },
  mounted() {
    axios.get('options/types-and-usages')
        .then(({data})=>{
          const houseTypes = data.data.filter(item => item.id === 'houseTypes')
          this.houseTypes = houseTypes[0].value
          this.selected = this.$store.state.editEstate.house_type
        })
  }
}
</script>

<style scoped>

</style>